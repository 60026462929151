import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import {AuthService} from '../auth.service';
import {MatProgressButtonOptions} from 'mat-progress-buttons';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public form: FormGroup;
  public errorMessage: string;
  public activatedRoute: ActivatedRoute

  spinnerButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Login',
    spinnerSize: 24,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: true,
    customClass: 'btn-block btn-lg m-t-20 m-b-20',
    mode: 'indeterminate',
  };


  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router, private route: ActivatedRoute) {
    this.activatedRoute = route;
  }

  ngOnInit() {
   this.activatedRoute.queryParams.subscribe(params => {
     if (params['reason'] === 'inactivity') {
       this.errorMessage = 'You have been logged out due to inactivity.';
     }});

    this.form = this.fb.group({
      email: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required, Validators.minLength(6)])]
    });

    this.form.statusChanges.subscribe(
      result => {
        this.spinnerButtonOptions.disabled = !this.form.valid;
      }
    );
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    } else {
      this.errorMessage = '';
    }
    const params = {'email': this.form.get('email').value, 'password': this.form.get('password').value};
    this.tryLogin(params);
  }

  tryLogin(value) {
    this.spinnerButtonOptions.active = true;
    this.authService.doLogin(value)
      .then(res => {
          this.spinnerButtonOptions.active = false;
          this.router.navigate(['/locates']);
        }
        , err => {
          this.spinnerButtonOptions.active = false;
          this.errorMessage = err.message;
        });
  }
}

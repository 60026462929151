import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {LocateStreamService} from './stream/locate-stream.service';
import {LocategridComponent} from './locategrid/locategrid.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../authentication/auth.service';
import {WatchlistComponent} from '../watchlist/watchlist.component';
import {User} from '../authentication/User/user';
import {HttpClient} from '@angular/common/http';
import {WatchSymbol} from '../watchlist/watchSymbol/watchSymbol';
import {$e} from 'codelyzer/angular/styles/chars';
import {Quote} from './quote';
import {Order} from './order';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import 'rxjs/add/observable/interval';

@Component({
  selector: 'app-locates',
  templateUrl: './locates.component.html',
  styleUrls: ['./locates.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LocatesComponent implements OnInit {
  @ViewChild('locateGrid1', {static: false}) locateGrid: LocategridComponent;
  @ViewChild('watchList', {static: false}) watchList: WatchlistComponent;
  public formLocator: FormGroup;
  public currentUser: User
  private traders: string[];
  public quote: WatchSymbol;

  constructor(private locateStreamService: LocateStreamService,
              private fb: FormBuilder,
              private authService: AuthService,
              private httpClient: HttpClient) {
  }

  ngOnInit() {
    this.formLocator = this.fb.group({
      trader: [null, Validators.compose([Validators.required])],
      symbol: [null, Validators.compose([Validators.required])],
      quantity: [null, Validators.compose([Validators.required])]
    });
    this.currentUser = new User(JSON.parse(localStorage.getItem('User')));
    if(this.currentUser.isManager()) {
      this.locateStreamService.subscribeManager();
    } else{
      this.locateStreamService.subscribeTraderID(this.currentUser.Handle);
    }
    this.traders = [];
    this.quote = null;
    this.formLocator.get('quantity').setValue(100);
  }

  public exportGrid() {
    this.locateGrid.onExportButton();
  }

  public onToggleFilterChanged(value) {
    this.locateGrid.SetFilter(value);
  }

  public GetQuote($event) {
    // const row = this.locateGrid.gridApi.getDisplayedRowAtIndex(1);
    // row.setDataValue('MinutesRemaining', 10);
    // var sub = Observable.interval(30000)
    //   .subscribe((val) => {
    //     const row = this.locateGrid.gridApi.getDisplayedRowAtIndex(1);
    //     row.setDataValue('MinutesRemaining', row.data.MinutesRemaining - 0.5);
    //   });

    if ($event.key === 'Enter' || $event.key === 'Tab') {
      const self = this;
      const symbol: string = this.formLocator.get('symbol').value;
      if (symbol != null && symbol.trim().length > 0) {
        this.watchList.GetQuote({Symbol: this.formLocator.get('symbol').value}).then(function (val) {
          self.quote = val;
        });
      } else {
        self.quote = null;
      }
    }

    if ($event.key === 'Enter') {
      $event.target.selectionStart = 0;
    }
    // this.watchList.AddSymbolToWatchlist({Symbol: this.formLocator.get('symbol').value});
    // this.formLocator.reset();
  }

  public NewOrder() {
    if (!this.isValidateOrder()) {
      return;
    }

    const user: User = JSON.parse(localStorage.getItem('User'));
    const self = this;
    const order = {
      Symbol: this.formLocator.get('symbol').value.toUpperCase(),
      OrdQty: this.formLocator.get('quantity').value,
      OrdType: 2,
      OrderStatus: 5,
      Trader: user.Handle
    };

    const param = {
      ticket: user.Ticket,
      intention: 'new',
      params: order
    };

    self.httpClient.put(environment.locateApiEndpoint + 'orders',
      param).subscribe(
      (val) => {
        console.log(val);
      },
      response => {
      },
      () => {
      });
  }

  HandleEnter($event) {
    if ($event.key === 'Enter') {
      this.NewOrder();
    }
  }

  public ProcessOrders(intention: string) {
    const self = this;
    this.locateGrid.getSelectedRows().forEach(function (value) {
      const user: User = JSON.parse(localStorage.getItem('User'));
      const param = {
        ticket: user.Ticket,
        intention: intention,
        params: value
      };
      self.httpClient.post(environment.locateApiEndpoint + 'orders',
        param).subscribe(
        (val) => {
          console.log(val);
        },
        response => {
          console.log(response);
        },
        () => {
          self.locateGrid.hasRowsSelected = false;
        });
    });
  }

  public isValidateOrder(): boolean {
    let isValid = true;
    const qty: number = this.formLocator.get('quantity').value;
    const symbol: string = this.formLocator.get('symbol').value;

    if (qty < 100 || qty === null) {
      this.formLocator.get('quantity').setErrors({'invalidQuantity': true});
      isValid = false;
    }

    if (symbol === null || symbol.trim().length === 0) {
      this.formLocator.get('symbol').setErrors({'invalidSymbol': true});
      isValid = false;
    }

    return isValid;
  }
}

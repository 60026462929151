import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {catchError} from 'rxjs/operators';
import * as firebase from 'firebase';
import {AuthService} from '../authentication/auth.service';
import {ValidationError} from 'ajv';

@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserprofileComponent implements OnInit {
  private user;
  public formProfile: FormGroup;
  public formPassword: FormGroup;
  public changePasswordInfoText;
  public updateProfileInfoText;

  constructor(private afAuth: AngularFireAuth, private fb: FormBuilder, private authService: AuthService) {
  }

  ngOnInit() {
    if (this.afAuth.auth.currentUser != null) {
      this.user = this.afAuth.auth.currentUser;
      this.formProfile = this.fb.group({
        email: [this.user.email, Validators.compose([Validators.required])],
        displayName: [this.user.displayName],
        currentPassword: [null, Validators.compose([Validators.required])],
      });

      this.formPassword = this.fb.group({
        currentPassword: [null, Validators.compose([Validators.required])],
        newPassword: [null, Validators.compose([Validators.required, Validators.minLength(8)])],
        confirmNewPassword: [null, Validators.compose([Validators.required, Validators.minLength(8)])]
      });
    }
  }

  public resetPassword() {
    const value = {
      email: this.afAuth.auth.currentUser.email,
      currentPassword: this.formPassword.get('currentPassword').value,
      newPassword: this.formPassword.get('newPassword').value
    };
    if (this.formPassword.get('currentPassword').value != null &&
      this.formPassword.get('newPassword').value != null &&
      this.formPassword.get('confirmNewPassword').value != null) {
      if (this.formPassword.get('confirmNewPassword').value === value.newPassword) {
        this.authService.resetPassword(value).then(() => {
          this.formPassword.get('currentPassword').reset();
          this.formPassword.get('newPassword').reset();
          this.formPassword.get('confirmNewPassword').reset();
          this.formPassword.get('currentPassword').setErrors(null);
          this.formPassword.get('newPassword').setErrors(null);
          this.formPassword.get('confirmNewPassword').setErrors(null);
          this.changePasswordInfoText = 'Password updated successfully.';
        }).catch((error) => {
          this.changePasswordInfoText = error;
        });
      } else {
        this.formPassword.get('newPassword').setErrors({'notMatching': true});
        this.formPassword.get('confirmNewPassword').setErrors({'notMatching': true});
      }
    }
  }

  public updateProfile() {
    if (this.formProfile.get('email').value != null && this.formProfile.get('currentPassword').value != null) {
      this.authService.updateProfile({
        displayName: this.formProfile.get('displayName').value,
        email: this.formProfile.get('email').value,
        currentPassword: this.formProfile.get('currentPassword').value
      }).then(() => {
        this.updateProfileInfoText = 'Profile saved successfully.';
      }).catch((error) => {
        this.updateProfileInfoText = error;
      });
    }
  }
}

import {formatDate} from '@angular/common';

export class Utils {

  public static createFileName(prefix: string, date: Date, suffix: string, extension: string): string {
    return (prefix || '')
      .concat(formatDate(date, 'yyMMdd', 'en-US'))
      .concat(suffix || '')
      .concat(extension || '');
  }
}

export class WatchSymbol {
  public Symbol: string;
  public Price: number;
  public isAvailable: boolean;

  constructor(data: any) {
    this.Symbol = data.Symbol;
    this.Price = data.Price;
    this.isAvailable = data.isAvailable;
  }
}

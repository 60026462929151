import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {Subscription} from 'rxjs';
import {MediaMatcher} from '@angular/cdk/layout';
import {MenuItems} from '../../../shared/menu-items/menu-items';
import {AuthService} from '../../../authentication/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent implements OnDestroy, OnInit {
  public config: PerfectScrollbarConfigInterface = {};

  readonly public;
  public userName: string;
  subscription: Subscription;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private authService: AuthService
  ) {
  }

  public logOut(): void {
    this.authService.doLogout();
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    const self = this;
    this.subscription = this.authService.userInfo$.subscribe(
      info => {
        self.userName = info;
      });
    self.userName = this.authService.getUserName();
  }
}


//
//
//
// {
//   public config: PerfectScrollbarConfigInterface = {};
//   // This is for Notifications
//   notifications: any[] = [
//     {
//       round: 'round-danger',
//       icon: 'ti-link',
//       title: 'Luanch Admin',
//       subject: 'Just see the my new admin!',
//       time: '9:30 AM'
//     },
//     {
//       round: 'round-success',
//       icon: 'ti-calendar',
//       title: 'Event today',
//       subject: 'Just a reminder that you have event',
//       time: '9:10 AM'
//     },
//     {
//       round: 'round-info',
//       icon: 'ti-settings',
//       title: 'Settings',
//       subject: 'You can customize this template as you want',
//       time: '9:08 AM'
//     },
//     {
//       round: 'round-primary',
//       icon: 'ti-user',
//       title: 'Pavan kumar',
//       subject: 'Just see the my admin!',
//       time: '9:00 AM'
//     }
//   ];
//
//   // This is for Mymessages
//   mymessages: any[] = [
//     {
//       useravatar: 'assets/images/users/1.jpg',
//       status: 'online',
//       from: 'Pavan kumar',
//       subject: 'Just see the my admin!',
//       time: '9:30 AM'
//     },
//     {
//       useravatar: 'assets/images/users/2.jpg',
//       status: 'busy',
//       from: 'Sonu Nigam',
//       subject: 'I have sung a song! See you at',
//       time: '9:10 AM'
//     },
//     {
//       useravatar: 'assets/images/users/2.jpg',
//       status: 'away',
//       from: 'Arijit Sinh',
//       subject: 'I am a singer!',
//       time: '9:08 AM'
//     },
//     {
//       useravatar: 'assets/images/users/4.jpg',
//       status: 'offline',
//       from: 'Pavan kumar',
//       subject: 'Just see the my admin!',
//       time: '9:00 AM'
//     }
//   ];
// }

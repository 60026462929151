export class Order {
  public idOrder: number;
  public Symbol: string;
  public Trader: string;
  public MPID: string;
  public OrdQty: number;
  public OrdPx: number;
  public OrdTime: Date;
  public CancelTime: Date;
  public OfferTime: Date;
  public OfferQty: number;
  public OfferPx: number;
  public ExecQty: number;
  public ExecPx: number;
  public ExecTime: Date;
  public idSource: number;
  public idReason: number;
  public OrdType: number;
  public OrderStatus: number;
  public CliOrdId: string;
  public TIF: number;
  public MktOrdQty: number;
  public MktExecQty: number;
  public ExpiryTime: Date;
  public MktStatus: string;

  private readonly time: Date;

  constructor(data: any) {
    if (data.OrdTime) {
      if (data.OrdTime.time) {
        this.OrdTime = new Date(data.OrdTime.time);
      } else {
        this.OrdTime = new Date(data.OrdTime);
      }
    }
    if (data.ExecTime) {
      if (data.ExecTime.time) {
        this.ExecTime = new Date(data.ExecTime.time);
      } else {
        this.ExecTime = new Date(data.ExecTime);
      }
    }

    if (data.CancelTime) {
      if (data.CancelTime.time) {
        this.CancelTime = new Date(data.CancelTime.time);
      } else {
        this.CancelTime = new Date(data.CancelTime);
      }
    }
    if (data.OfferTime) {
      if (data.OfferTime.time) {
        this.OfferTime = new Date(data.OfferTime.time);
      } else {
        this.OfferTime = new Date(data.OfferTime);
      }
    }

    if (this.OrderStatus === 1 || this.OrderStatus === 2) {
      this.time = this.ExecTime;
    } else if (this.OrderStatus === 4 || this.OrderStatus === 8) {
      this.time = this.CancelTime;
    } else {
      this.time = this.OrdTime;
    }

    if (data.ExpiryTime) {
      if (data.ExpiryTime.time && data.ExpiryTime.year !== 70) {
        this.ExpiryTime = new Date(data.ExpiryTime.time);
      } else {
        this.ExpiryTime = null;
      }
    }

    this.idOrder = data.idOrder;
    this.Symbol = data.Symbol;
    this.Trader = data.Trader;
    this.MPID = data.MPID;
    this.OrdQty = data.OrdQty;
    this.OrdPx = data.OrdPx;
    this.OfferQty = data.OfferQty;
    this.OfferPx = data.OfferPx;
    this.ExecQty = data.ExecQty;
    this.ExecPx = data.ExecPx;
    this.idSource = data.idSource;
    this.idReason = data.idReason;
    this.OrdType = data.OrdType;
    this.OrderStatus = data.OrderStatus;
    this.CliOrdId = data.CliOrdId;
    this.TIF = data.TIF;
    this.MktOrdQty = data.MktOrdQty;
    this.MktExecQty = data.MktExecQty;
    this.MktStatus = data.MktStatus;
  }

}
